import { IonPage, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import Header from "../../organisms/Header";
import ChallengeList from "../../organisms/ChallengeList";
import ProfileGrid from "../../organisms/ProfileGrid";
import { NavbarContext } from "../../../routes";
import React, { useEffect, useState } from "react";
import { useQuery } from "urql";
import { UserQuery } from "../../../Queries/UserQueries";
import "./home.scss";

const Home = () => {
  const {setShow} = React.useContext(NavbarContext);
  const [profile, setProfile] = useState();

  useEffect(() => {
    setShow(true);
  }, []);

  useIonViewWillLeave(() => {
    setProfile(false);
  });
  
  useIonViewWillEnter(() => {
    setProfile(true);
  });

  const [result] = useQuery({
    query: UserQuery,
  });

  const { data,fetching, error } = result;
  
  if (error) return <p>Oh no... {error.message}</p>;
  if (fetching) return (<></>);

  return (
    <IonPage className="home">
      <Header>
        {
          profile ? 
            <ProfileGrid data={data}/> : <></>
        }
      </Header>
      <ChallengeList  data={data.currentUser.challenges}/>
    </IonPage>
  );
};

export default Home;
