import "./header.scss";

const Header = ({ children }) => {
  return (
    <div className="homeheader-background">
      {children}
    </div>
  );
};
  
export default Header;
  
