import Spinner from "../../atoms/spinner";
import {useQuery} from "urql";
import "./friendinfolist.scss";
import { matchPath } from "react-router-dom";
import ProgressBar from "../../molecules/ProgressBar";
import ProfilePhoto from "../../molecules/ProfilePhoto";

const UserQuery = `
  query($id: String!) {
    user(userId: $id) {
      id
      username
      experiencePoints
    }
  }
`;

const FriendInfoList = () => {
  const match = matchPath(window.location.pathname, {
    path: "/friendsinfo/:id",
    exact: true,
    strict: false
  });
  if(match == null)
    return (<></>);
  
  const id = match.params.id;
  const [result] = useQuery({
    query: UserQuery,
    variables: { id: id },
  });

  const { data, fetching, error } = result;

  if (error) return <p>Oh no... {error.message}</p>;
  if (fetching) return <Spinner />;

  return (
    <>
      <ProfilePhoto />
      <div className="FriendListGrid">
        <h2>{data.user.username}</h2>

        <ProgressBar experiencePoints={data.user.experiencePoints} />
      </div>
    </>
  );
};

export default FriendInfoList;
