/* eslint-disable react/no-unescaped-entities */
import {
  IonPage,
  IonContent,
  IonBackButton,
  IonPopover,
  IonItem,
  IonList,
  IonButton,
  useIonViewWillLeave,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useQuery } from "urql";
import MarkdownContent from "../../atoms/MarkdownContent";
import { NavbarContext } from "../../../routes";
import React, { useEffect, useState} from "react";
import { useAuth } from "react-oidc-context";
import "./challenge.scss";
import tripleDot from "../../../images/tripleDot.svg";
import { motion } from "framer-motion";
import { ChallengeQuery } from "../../../Queries/ChallengeQueries";

const Challenge = ({challengeID, finished}) => {
  const auth = useAuth();
  const {setShow} = React.useContext(NavbarContext);
  const history = useHistory();
  const [type, setType] = useState("");
  const [showPopover,setShowPopover] = useState(false);
  const [challengeInfo, setChallengeInfo] = useState(false);

  const [result] = useQuery({
    query: ChallengeQuery,
    variables: { id: challengeID },
  });

  const { data, fetching, error } = result;
  
  useIonViewWillLeave(() => {
    setShowPopover(false);
  }, []);

  useEffect(() => {
    if(!auth.isAuthenticated && !auth.isLoading){
      setShow(false);
    }else{
      setShow(true);
    }
  }, [auth]);

  useEffect(() => {
    if(data){
      setType(getTypeURI(data.challenge.typeId));
      
      let finishedLocation = {
        pathname: "/challenge/" + challengeID + "/done",
        state: {
          questions: data.challenge.questions,
          challenge: data
        }
      };

      if(data.challenge.finished && isQuiz(data.challenge.typeId)){
        console.log(finishedLocation);
        history.push(finishedLocation);
        history.go();
      }
    }
    
  }, [data]);

  /**
   * Gets the type of the challenge
   * @param {string} type 
   * @returns 
   */
  const getTypeURI = (type) => {
    switch (type) {
    case 1:
      return "mapquiz";
    case 2:
      return "quiz";
    case 3: 
      return "photo";
    }
  };

  /**
   * Gets the type of the challenge
   * @param {string} type 
   * @returns 
   */
  const getType = (type) => {
    switch (type) {
    case 1:
      return "Map Quiz";
    case 2:
      return "Quiz";
    case 3: 
      return "Photo";
    }
  };

  const isQuiz = (type) => {
    switch (type) {
    case 1:
      return true;
    case 2:
      return true;
    default:
      return false;
    }
  };

  /**
   * gives information about a challenge 
   * @param {string} type 
   * @returns The information for a challenge type
   */
  const challengeInformation = (type) => {
    switch (type) {
    case "Map Quiz": //Map Quiz
      return "Dit is een Map Quiz challenge";
    case "Quiz": //Quiz
      return "Dit is een Quiz challenge";
    case "Photo": //Photo
      return "Dit is een foto challenge";
    case "Registration": //registratie
      return "Dit is een registratie challenge";
    case "Cursus": //cursus
      return "Dit is een cursus challenge";
    }
  };

  if (error) return <p>Oh no... {error.message}</p>;
  if (fetching) return  <></>;

  if(finished && isQuiz(data.challenge.typeId)) return <></>;

  return (
    <>
      <IonPopover
        isOpen={showPopover}
        onDidDismiss={() => setShowPopover(false)}
      >
        <IonList>
          <IonItem button href={"/challenge/" + challengeID + "/registraties/map"} isOpen={true}>Kaart</IonItem>
          {/* commented out for now */}
          {/* <IonItem button href={"/challenge/" + challengeID + "/registraties/photo"}>Jouw foto's</IonItem> */}
          <IonItem button onclick={() => {setShowPopover(false); setChallengeInfo(!challengeInfo);}}>{challengeInfo ? "Challenge" : "Informatie"}</IonItem>
          <IonItem lines="none" button onclick={() => {setShowPopover(false);}}>Close</IonItem>
        </IonList>
      </IonPopover>

      <IonPage className="challengePage">
        <IonContent>
          <div className="Challenge">
            <div className="challenge__image" style={{backgroundImage:`linear-gradient(180deg, rgba(244, 241, 241, 0) 50.29%, rgba(6, 6, 6, 0.6) 100%), url("${data.challenge.imageUrl}")`}}>
              <div className="challenge__navigation">
                <div className="backArrow">
                  <IonBackButton className="backButton" defaultHref="/home" />
                </div>
                {getType(data.challenge.typeId) === "Photo" ? 
                  <img className="challenge__options" src={tripleDot} onClick={() => {setShowPopover(true);}} />
                  : null} 
              </div>
              <div className="challenge__bottomrow">
                <div className="challenge__quickstats">
                  <div className="reward">
                    <p className="one">Beloning</p>
                    <p className="two">{data.challenge.defaultXp} xp</p>
                  </div>
                  <div className="type">
                    <p className="one">Type</p>
                    <p className="two">{getType(data.challenge.typeId)}</p>
                  </div>
                </div>
              
                <motion.div className="challenge__joinButton"
                  animate={{ scale: [1, 0.9, 1],
                    rotate: [0, 12, -12, 0] }}
                  transition={{
                    ease:"easeInOut",
                    repeat: Infinity,
                    repeatType: "reverse",
                    repeatDelay: 3,
                  }}
                >
                  <IonButton mode="ios" className="hidden-options play-button" href={"challenge/"+ challengeID +"/"+ type}>
                    <div className={getType(data.challenge.typeId) === "Photo" ? "hexagonPhoto" : "hexagonQuiz"}>
                      <div className="hexTop"></div>
                      <div className="hexBottom"></div>
                    </div>
                  </IonButton>
                  
                </motion.div>
              </div>
            </div>

            <div className="Challenge__Description">
              {!challengeInfo ? <div>
                <h1>{data.challenge.name}</h1>
                <div className="Challenge__Description__Text">
                  <MarkdownContent content={data.challenge.description}/>
                </div>
              </div> : 
                <div>
                  <h1>{data.challenge.name} information</h1>
                  <MarkdownContent content={challengeInformation(getType(data.challenge.typeId))}/>
                </div>
              }
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Challenge;
