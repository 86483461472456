import { gql } from "urql";

export const ChallengesQuery = gql`
  query($title: String) {
      challenges(filter: {
          joined: false,
          title: $title
      }) {
          id
          name
          description
          finished
          active
          imageUrl
          questions{
            registrations{
              id
            }
          }
      }
  }
`;

export const AllChallengeQuery = gql`
  query {
      challenges(filter: {
          joined: true
      }) {
          id
          name
          description
          finished
          active
          imageUrl
          location{
            x,
            y
          }
          questions{
            registrations{
              id
            }
          }
      }
  }
`;

export const PlayChallengeMutation = gql`
mutation($id: Int!){
  playChallenge(id: $id){
		id
	}
}
`;

export const ChallengeQuery = gql`
  query($id: String!) {
    challenge(id: $id){
      id
      typeId
      finished
      name
      defaultXp
      description
      questions{
          id
          index
          question
          description
          answers
          typeId
          location {
            x
            y
          }
          registrations{
            id
            value
            xp
            correctAnswers
          }
      }
      imageUrl
    }
  }
`;


