import {searchUserQuery} from "../../../Queries/FriendQueries";
import FriendCard from "../FriendCard";
import { useQuery } from "urql";
import { IonContent } from "@ionic/react";
import "./searchResultList.scss";
import CenteredText from "../../atoms/CenteredText";

const SearchResultList = (props) => {
  const [result, searchAgain] = useQuery({
    query: searchUserQuery,
    variables: { input: props.search},
    requestPolicy: "cache-and-network",
  });
  const { data, fetching, error } = result;

  if (error) return <p>Oh no... {error.message}</p>;
  if (fetching && data == undefined) return (<></>);

  const users = [...data.users.filter(user => user.friendship === null || user.friendship.status !== "ACCEPTED")];
    
  return (
    users.length > 0 ?
      <IonContent className="scroll resultList">
        {users.map((user) => {
          return (
            <FriendCard
              key={user.id}
              user={user}
              onAdd={async () => {
                await props.onAdd(user.id);
                searchAgain({requestPolicy: "cache-and-network",});
              }}
            ></FriendCard>
          );
        })
        }
      </IonContent> :
      <CenteredText>{props.search.length < 3  ? "Zoek een gebruiker om vrienden te worden!" : "Geen gebruikers gevonden" }</CenteredText>
  );
};
export default SearchResultList;
