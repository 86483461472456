import { IonIcon } from "@ionic/react";
import { closeCircleOutline, checkmarkCircleOutline, closeCircle, checkmarkCircle } from "ionicons/icons";
import { useState } from "react";
import "./acceptDenyButtons.scss";
import { motion } from "framer-motion";

const AcceptDenyButtons = ({onDeny, onAccept}) => {
  const [accepted, setAccepted] = useState(false);
  const [denied, setDenied] = useState(false);
  return (
    <div className="buttons">
      <motion.div
        className=""
        whileHover={{scale: 0.9}}
        whileTap={{scale: 0.9}}
      >
        <IonIcon 
          className="close" 
          icon={denied ? closeCircle : closeCircleOutline} 
          size="large" 
          onClick={() => {
            setDenied(true);
            setTimeout(onDeny, 1000);
          }}></IonIcon>
      </motion.div>
      <motion.div
        className=""
        whileHover={{scale: 0.9}}
        whileTap={{scale: 0.9}}
      >
        <IonIcon 
          className="check" 
          icon={accepted ? checkmarkCircle : checkmarkCircleOutline} 
          size="large" 
          onClick={() => {
            setAccepted(true);
            setTimeout(onAccept, 1000);
          }}></IonIcon>
      </motion.div>
    </div>
  );
};

export default AcceptDenyButtons;
