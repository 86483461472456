import { matchPath } from "react-router";
import { IonPage, IonContent, IonBackButton, IonButton } from "@ionic/react";
import MarkdownContent from "../../atoms/MarkdownContent";
import "./ChallengeJoin.scss";
import { ChallengeQuery } from "../../../Queries/ChallengeQueries";
import { useQuery, useMutation } from "urql";
import { PlayChallengeMutation } from "../../../Queries/ChallengeQueries";

const ChallengeJoin = () => {
  const match = matchPath(window.location.pathname, {
    path: "/challenge/:id/join",
    exact: true,
    strict: false
  });
  if(match == null)
    return (<></>);
  
  const id = match.params.id;
  const [, playChallengeRequest] = useMutation(PlayChallengeMutation);

  const [result] = useQuery({
    query: ChallengeQuery,
    variables: { id: id },
  });

  const { data, fetching, error } = result;

  if (error) return <p>Oh no... {error.message}</p>;
  if (fetching) return (<></>);

  /**
   * Gets the type of the challenge
   * @param {string} type 
   * @returns The type of the challenge
   */
  const getType = (type) => {
    switch (type) {
    case 1:
      return "Map Quiz";
    case 2:
      return "Quiz";
    case 3: 
      return "Photo";
    }
  };

  /**
   * Join the challenge
   */
  const joinChallenge = () => {
    playChallengeRequest({id: id});
  };

  return (
    <IonPage className="ChallengeJoin">
      <IonContent>
        <div className="Challenge">
          <div className="challenge__image" style={{backgroundImage:`linear-gradient(180deg, rgba(244, 241, 241, 0) 50.29%, rgba(6, 6, 6, 0.6) 100%), url("${data.challenge.imageUrl}")`}}>
            <div className="challenge__navigation">
              <div className="backArrow">
                <IonBackButton className="backButton" defaultHref="/home" />
              </div>
            </div>
            <div className="challenge__bottomrow">
              <div className="challenge__quickstats">
                <div className="reward">
                  <p className="one">Beloning</p>
                  <p className="two">{data.challenge.defaultXp} xp</p>
                </div>
                <div className="type">
                  <p className="one">Type</p>
                  <p className="two">{getType(data.challenge.typeId)}</p>
                </div>
              </div>
              
            </div>
          </div>

          <div className="Challenge__Description">
            <div>
              <h1>{data.challenge.name}</h1>
              <div className="Challenge__Description__Text">
                <MarkdownContent content={data.challenge.description}/>
              </div>
            </div>

            <IonButton className="challenge__joinButton defaultButton" routerLink={"/challenge/" + id} onClick={() => joinChallenge()}>
              Deelnemen
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ChallengeJoin;
