/* eslint-disable no-unused-vars */
import { IonPage, IonIcon, useIonViewDidEnter, useIonViewWillLeave, useIonViewDidLeave } from "@ionic/react";
import ValidatieCard from "../../organisms/validatieCard";
import React, {useEffect, useState} from "react";
import { NavbarContext } from "../../../routes";
import "./validatie.scss";
import { checkmarkCircleOutline, closeCircleOutline, playSkipForwardCircleOutline, images } from "ionicons/icons";
import {validationQueue, validationMutation} from "../../../Queries/ValidationQueries";
import { useQuery, useMutation } from "urql";

const ValidatiePage = () => {
  const {setShow} = React.useContext(NavbarContext);

  useEffect(() => {
    setShow(true);
  }, []);

  const [validationsMutationResult, createValidation] = useMutation(validationMutation);
  const [result, reexecuteQuery] = useQuery({
    query: validationQueue,
  });

  const { data, fetching, error } = result;
  const [selectedCard, setSelectedCard] = useState(null);
  const [correct, setCorrect] = useState("");
  const [queueLength, setQueueLength] = useState(0);

  useEffect(() => {

    reexecuteQuery({ requestPolicy: "network-only" });
    
  }, []);

  useIonViewDidLeave(() => {
    data.validationQueue = null;
  });

  useEffect(() => {

  }, [queueLength]);

  useEffect(() =>{
    if(data && selectedCard == null){
      setSelectedCard(data.validationQueue.length - 1);
      setQueueLength(data.validationQueue.length);
    }

    if(selectedCard){
      setCorrect("");
    }
    
  }, [correct, selectedCard, data]);
 
  /**
   * Creates a new validation
   * @param {event} event 
   */
  const match = (event) => {
    setQueueLength(queueLength - 1);
    createValidation({id: event.id, value: event.value});
  };

  /**
   * Gets a random degree
   * @returns {int}
   */
  const getRandomRotation = () => {
    let r = Math.round((Math.random() * 4));
    let p = Math.round(Math.random());
    
    if(p == 0){
      return r;
    }else{
      return -r;
    }
  };

  if (error) return <p>Oh no... {error.message}</p>;
  if (fetching) return <></>;

  return (
    <IonPage className="Validatie">
      <div className="Validation__stack">
        {
          queueLength > 0 ?
            data.validationQueue.map((element, index) => {
              return (<ValidatieCard
                correct={correct}
                value={element.value}
                imageUrl={element.imageUrl}
                selected={selectedCard}
                rotation={getRandomRotation()}
                registrationId={element.id}
                onMatch={(event)=> {
                  match(event);
                  setSelectedCard(prev => prev - 1);
                }}
                index={index}
                key={index}
              ></ValidatieCard>);
            })
            :
            <div className="Validatie__centered">
              <IonIcon className="Validatie__images" icon={images}></IonIcon>
              <h1>Er zijn geen nieuwe validaties. Kom later terug!</h1>
            </div>
            
        }
      </div>
      <div className="Validatie__Control">
        <IonIcon className="wrong" icon={closeCircleOutline} size="large" onClick={() => setCorrect("false")}></IonIcon>
        <IonIcon className="skip" icon={playSkipForwardCircleOutline} size="large" onClick={() => setCorrect("skip")}></IonIcon>
        <IonIcon className="correct" icon={checkmarkCircleOutline} size="large" onClick={() => setCorrect("true")}></IonIcon>
      </div>
      
    </IonPage>
  );
};

export default ValidatiePage;
