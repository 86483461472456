import Photo from "../components/templates/Photo";
import { matchPath } from "react-router";

const PhotoPage = () => {
  const match = matchPath(window.location.pathname, {
    path: "/challenge/:id/photo",
    exact: true,
    strict: false
  });
  if(match == null)
    return (<></>);

  return <Photo ChallengeId={match.params.id} />;
};

export default PhotoPage;
