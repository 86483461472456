import bee from "../../../images/colored_bee.svg";
import {friendshipsQuery} from "../../../Queries/FriendQueries";
import {useQuery} from "urql";
import "./friendlist.scss";


const FriendList = () => {

  const [result] = useQuery({
    query: friendshipsQuery,
  });

  const { data, fetching, error } = result;

  if (error) return <p>Oh no... {error.message}</p>;
  if (fetching) return <></>;

  return (
    <>
      <div className="FriendListGrid">
        {data.friendships.filter(friendship => friendship.status === "ACCEPTED").map((user) => (
          <div key={user.id} className="grid-item profilePhoto" onClick={() => {location.href=`/friendsinfo/${user.friend.id}`;}}>
            <div className="profilepicture-container" style={{margin: "auto"}}>
              <img className="profilepicture" src={bee} />
            </div>
            <h2>{user.friend.username}</h2>
          </div>
        ))}
      </div>
    </>
  );
};

export default FriendList;
