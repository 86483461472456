import React, { useEffect, useState, useRef } from "react";
import {
  IonButton, IonInput, IonItem
} from "@ionic/react";
import { useMutation } from "urql";
import Send from "../../../assets/send.svg";
import Button from "../../atoms/Button";
import "./question.scss";
import { createQuestionRegistration } from "../../../Queries/RegistrationQuery";
import { useParams } from "react-router";

const Question = (props) => {
  const {id} = useParams();
  const questionRef = useRef();

  //-------------------------- states & variables ------------------------------------------
  const [value , setValue] = useState("");
  const [selected, setSelected] = useState(-1);
  const [open, setOpen] = useState(-1);
  const [answered, setAnswered] = useState(false);
  const [registration, setRegistration] = useState("");
  const [result , executeMutation] = useMutation(createQuestionRegistration);

  //-------------------------- lifecycle ------------------------------------------
  useEffect(() => {
    setSelected(-1);
  }, [result, props]);

  useEffect(() => {
    if(props){
      setAnswered(props.answered);

      if(props.types == 1){
        setOpen(false);
      }else{
        setOpen(true);
      }
    }
  }, [props]);

  //-------------------------- functions ------------------------------------------

  /**
   * Registrates the answer on the question
   * @returns The value, xp and the correct answers
   */
  const registrateChallenge = async () => {
    console.log(id);
    if (value == "" && !open) return <p>Vul een antwoord in</p>;

    await executeMutation({ challengeId: id, value: value, typeId: 1,  questionId: props.questionId, }).then(
      (result) => {
        let { data, fetching, error} = result;
        
        if (fetching) return <></>;
        if (error) return <p>Oh no... {error.message}</p>;

        props.onAnswered(data, props.questionId);
        setAnswered(true);
        setRegistration(data.createQuestionRegistration);
      } 
    );
    return result;

  };

  /**
   * Checks the given answer
   * @param {string} reg 
   * @param {string} answer 
   * @returns 
   */
  const checkAnswer = (reg, answer) => {
    if(reg == ""){
      reg = props.registration;
      reg = reg[0];
    }
    
    if(typeof(reg) === "undefined"){
      return "QuestionButton";
    }

    if ((reg.xp > 0 && reg.value == answer) || reg.correctAnswers.includes(answer)){
      return "QuestionButton buttonCorrect";
    }else if (reg.value == answer){
      return "QuestionButton buttonFalse";
    }

    return "QuestionButton";
    
  };

  //-------------------------- Computed ------------------------------------------

  /**
   * The send button
   * @returns The html for the button
   */
  const sendButton = () => {
    if(answered) return;

    return (
      <div className="sendQuestionButton__container">
        <Button className={"sendQuestionButton"} onClick={() => registrateChallenge()} hidden={props.answered}><img src={Send}></img></Button>
      </div>
    );
  };

  /**
   * Selects an answer
   * @param {string} answer 
   * @param {int} key 
   * @returns 
   */
  const selectAnswer = (answer, key) => {
    if(answered) return;

    setSelected(key);
    setValue(answer);
  };

  /**
   * Checks if there is a selected answer
   * @param {int} key 
   * @returns A boolean
   */
  const checkSelected = (key) => {
    if(selected == key) return false;

    return true;
  };

  //-------------------------- JSX ------------------------------------------

  const answers = () => {
    if(props.types == 1){
      return props.answers.map(
        (answer, i) => {
          return(<IonButton className={`${checkAnswer(registration, answer)} ${checkSelected(i) ? "" : "buttonSelected"}`} key={i} onClick={() => selectAnswer(answer, i)}>{answer}</IonButton>);
        }
      );
    }else{
      if(answered){
        return(<h1>{props.registration[0].value}</h1>);
      }
     
      return(
        <div>
          <IonItem className="textField" >
            <IonInput pattern="email" value={value} type="email" placeholder="Vul je antwoord in!" onIonChange={(e) => {setValue(e.detail.value);}}></IonInput>
          </IonItem>
         
        </div>);
    }
  };

  if(!props.active){
    return(<></>);
  }

  return(
    <div className="Question" ref={questionRef}>
      <div className="Question__information">
        <h1>{props.title}</h1>
        <span>
          {props.description}
        </span>
      </div>
      <div className="Question__Answer">
        {answers()}    
      </div>
      
      {sendButton()}
      
      
    </div>
  );
};

export default Question;
