/* eslint-disable no-unused-vars */
import { IonSearchbar } from "@ionic/react";
import { useMutation } from "urql";
import {sendFriendRequestQuery} from "../../../Queries/FriendQueries";
import {useState} from "react";
import {useIonToast } from "@ionic/react";
import SearchResultList from "../../molecules/SearchResultList";

import "./friendSearchResult.scss";

const FriendSearchResults = () => {
  const [searchBar, setSearchBar] = useState("");
  const [present, dismiss] = useIonToast();
  
  const [sendFriendRequestMutation, sendFriendRequest] = useMutation(sendFriendRequestQuery);

  return (
    <>
      <div className="searchBar">
        <IonSearchbar value={searchBar} onIonChange={e => setSearchBar(e.detail.value)}></IonSearchbar>
      </div>  
      <SearchResultList
        search={searchBar}
        onAdd={async (id) => {
          const data = await sendFriendRequest({userId: id});
          if(data.error == null){
            present({message: "Vriendschap verzoek gestuurd!", duration: 3000});
          } else {
            present({message: "Kon geen vriendschap verzoek sturen", duration: 3000});
          }
        }}
      ></SearchResultList>
    </>
  );
};

export default FriendSearchResults;
