import {
  IonProgressBar,
  useIonViewDidLeave,
  IonPage,
} from "@ionic/react";
import Map from "../../organisms/Map/Map";
import { useState, useEffect } from "react";
import { useQuery } from "urql";
import Question from "../../organisms/Question";
import CustomTextMarker from "../../molecules/TextMarker/CustomTextMarker";
import React from "react";
import { NavbarContext } from "../../../routes";
import Button from "../../atoms/Button";
import { useHistory, matchPath } from "react-router-dom";
import arrow from "../../../images/arrow.svg";
import QuizFeedbackPopup from "../../atoms/QuizFeedbackPopup";
import { useAuth } from "react-oidc-context";
import "./mapquiz.scss";

//-------------------------- GQL ------------------------------------------

const challengeQuery = `
  query($id: String!) {
    challenge(id: $id){
      id
      imageUrl
      typeId
      configuration
      questions{
        id
        index
        question
        description
        answers
        typeId
        location {
          x
          y
        }
        registrations {
          id
          value
          xp
          correctAnswers
        }
      }
    }
  }
`;



const MapQuiz = () => {
  const auth = useAuth();
  const match = matchPath(window.location.pathname, {
    path: "/challenge/:id/mapquiz",
    exact: true,
    strict: false
  });
  if(match == null)
    return (<></>);
  //-------------------------- States & Variables -----------------------------------
  const id = match.params.id;

  const [visible, setVisible] = useState(true);
  const [selected, setSelected] = useState(0);//eslint-disable-line
  const [completedQuestions, setCompletedQuestions] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [result, executeQuery] = useQuery({//eslint-disable-line
    query: challengeQuery,
    requestPolicy: "network-only",
    variables: { id },
  });
  const [showPopover, setShowPopover] = useState(false);//eslint-disable-line
  const [correct, setCorrect] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState([]);

  const history = useHistory();

  const { setShow } = React.useContext(NavbarContext);

  const { data, fetching, error } = result; //eslint-disable-line

  //-------------------------- Life Cyle ------------------------------------------

  useEffect(() => {
    if(!auth.isAuthenticated && !auth.isLoading){
      setShow(false);
    }else{
      setShow(true);
    }
  }, [auth]);

  useIonViewDidLeave(() => {
    setVisible(false);
  });


  useEffect(() => {
    
    if (data) {
      createQuestionObject(data);
    }

  }, [data]);

  useEffect(() => {
    if (questions) {
      progress();
    }
  }, [questions]);

  //-------------------------- Computed ------------------------------------------

  /**
   * Keeps track of the progress on the challenge
   */
  const progress = () => {
    var x = 0;

    questions.map((question) => {
      if (question.answered) {
        x++;
      }
    });

    setCompletedQuestions(x);
  };

  //-------------------------- Functions ------------------------------------------

  /**
   * Creates a questionObject
   * @param {JSON} data 
   */
  const createQuestionObject = (data) => {
    if (typeof (data) === "undefined") setQuestions([]);
    var qList = [];

    data.challenge.questions.map(
      (question) => {
        let q = {};

        q.id = question.id;

        q.question = question.question;
        q.description = question.description;
        q.answers = question.answers;
        q.qType = question.typeId;
        if (typeof (question.registrations) !== "undefined") q.registrations = question.registrations;

        if (question.registrations.length > 0) { q.answered = true; } else { q.answered = false; }
        if (question.registrations.length > 0 && question.registrations[0].xp > 0) { q.correct = true; } else { q.correct = false; }

        if (question.location != null) {
          q.location = {
            x: question.location.x,
            y: question.location.y
          };
        }

        q.index = question.index;
        qList.push(q);

      }
    );

    setQuestions(qList);
  };

  /**
   * Finds the next question
   */
  const findNextQuestion = () => {
    for (var i = 0; i < questions.length; i++) {
      if (questions[i].answered == false) {
        setSelected(i);
        break;
      }
    }
  }; 

  /**
   * 
   * @param {JSON} data 
   * @param {int} id 
   */
  const finishedQuestion = (data, id) => {
    setCompletedQuestions(completedQuestions + 1);

    let z = questions.map(
      (question) => {
        if (question.id == id) {
          question.answered = true;
          question.registrations[0] = data.createQuestionRegistration;
          if (data.createQuestionRegistration.xp > 0) {
            question.correct = true;
            setCorrect(true);
            setShowPopover(true);
          } else {
            question.correct = false;
            setCorrect(false);
            setCorrectAnswers(question.registrations[0].correctAnswers);
            setShowPopover(true);
          }
          return question;
        }
        return question;
      }
    );
    setQuestions(z);

  };

  /**
   * Skips to the next question
   * @param {int} number 
   */
  const increaseSelected = (number) => {

    if (selected + number < 0) {
      setSelected(0);
      return;
    }

    if (selected + number > data.challenge.questions.length - 1) {
      setSelected(data.challenge.questions.length - 1);
      return;
    }

    setSelected(selected + number);
  };

  /**
   * Sets to a question based on the id
   * @param {int} id 
   */
  const markerSelected = (id) => {
    setSelected(id);
  };

  /**
   * Finds a question and closes the popup
   */
  const closeAndNext = () => {
    findNextQuestion();
    setShowPopover(false);
  };

  //-------------------------- JSX ------------------------------------------
  if (error) return <p>Oh no... {error.message}</p>;
  if (fetching) return <p>Oh no... {fetching}</p>;

  const location = {
    pathname: "/challenge/" + id + "/done",
    state: {
      challenge: data,
      questions: questions
    }
  };

  return (
    <IonPage className="MapQuiz">

      <QuizFeedbackPopup 
        showPopover={showPopover} 
        setShowPopover={setShowPopover} 
        correct={correct} 
        correctAnswers={correctAnswers} 
        closeAndNext={closeAndNext}
      />

      <div className="MapQuiz__Map">
        {visible && <Map mapKey={"quizMap"} id="quizMap" configuration={data.challenge.configuration} Markers={
          questions.filter(q => q.location != null).map(
            (question, i) => {
              return (<CustomTextMarker
                key={i}
                id={i}
                position={[question.location.y, question.location.x]}
                onClick={markerSelected}
                text={question.question}
                colour={question.correct}
                answered={question.answered}
                selected={selected}
                index={question.index}
                zoom={22}
              />);
            }
          )
        } ></Map>}
      </div>

      <div className="MapQuiz__Question">
        <div className="MapQuiz__Question__Descriptions">
          <div>
            <div className="Bar">
              <img className="previous" src={arrow} onClick={() => { increaseSelected(-1); }} />
              <IonProgressBar
                className="QuizProgress"
                value={completedQuestions / questions.length}
              ></IonProgressBar>
              <img className="next" src={arrow} onClick={() => { increaseSelected(1); }} />

            </div>
          </div>

          <b>Vraag {questions[selected]?.index ?? 0}</b>

          {
            questions.map(
              (question, i) => {
                return (<Question
                  active={(selected == i) ? true : false}
                  key={i}
                  onAnswered={finishedQuestion}
                  questionId={question.id}
                  title={question.question}
                  types={question.qType}
                  description={question.description}
                  answers={question.answers}
                  answered={question.answered}
                  registration={question.registrations}
                />);
              }
            )
          }
        </div>
      </div>

      {data.challenge.questions.length === completedQuestions ?
        <Button className="finishChallenge" onClick={() => {
          history.replace(location);
          history.go();
        }}>Finish Challenge</Button> : null}


    </IonPage>
  );
};

export default MapQuiz;
