import "./ProgressBar.scss";
import {IonProgressBar, IonRow} from "@ionic/react";

const ProgressBar = (props) => {
  //Current xp of the user
  const currentUserXp = props.data;
  
  //Amount of xp needed for level 1
  const baseXp = 75;

  /**
   * Calculates your level based on your experience
   * @param {int} experience 
   * @returns The level of the player
   */
  const calculateLevel = (experience) => {
    let level = 1;
    for(let i = baseXp; i < experience; i = i * 1.25) {
      if(i <= experience){
        level++;
      }
    } 
    return level;
  };

  //The current lvel
  const currentLevel = calculateLevel(currentUserXp);

  //The next level 
  const nextLevel = calculateLevel(currentUserXp) + 1;

  return (
    <>
      <IonRow className="progressBar">
        <div className="hexagon">
          {calculateLevel(currentUserXp)}
        </div>
        <IonProgressBar className="xpBar" value={currentLevel / nextLevel} />
      </IonRow>
    </>
  );
};

export default ProgressBar;
