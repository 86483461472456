import { IonButton } from "@ionic/react";
import "./button.scss";

const Button = ({ 
  children,
  className,
  onClick,
  href,
  fill

}) => {

  /**
   * handles the button click
   * @param {any} e 
   */
  const handleClick = (e) => {
    if(onClick){
      onClick(e);
    }
  };

  const classList = [
    "defaultButton",
    className
  ] .join(" ").trim();

  const buttonProps = {
    className: classList,
    onClick: handleClick,
    href: href,
    fill: fill
  };

  return (
    <IonButton {...buttonProps}>{children}</IonButton>
  );
};

export default Button;
