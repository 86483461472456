import { IonGrid, IonRouterLink } from "@ionic/react";
import { useAuth } from "react-oidc-context";
import ProfileInformation from "../../molecules/ProfileInformation";
import ProfilePhoto from "../../molecules/ProfilePhoto";
import "./profileGrid.scss";

const ProfileGrid = (props) => {
  const auth = useAuth();
  return (
    <IonRouterLink routerLink="/profile">
      <IonGrid className="profileGrid">
        <div className="profilePhotoName">
          <ProfilePhoto />
          <h1 className="greeting">Hi, {auth.user?.profile.preferred_username}!</h1>
        </div>
        <ProfileInformation data={props.data}/>
      </IonGrid>
    </IonRouterLink>
    
  );
};

export default ProfileGrid;
