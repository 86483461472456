
import Button from "../../atoms/Button";
import {motion} from "framer-motion";
import "./friendCard.scss";
import ProfilePhoto from "../ProfilePhoto";
import AcceptDenyButtons from "../AcceptDenyButtons";

const FriendCard = ({user, request, onAdd, onAccept, onDeny}) => {
  const variants = {
    bounce: {scale: 0.9},
    none: {}
  };
  const canAdd = user.friendship == null || user.friendship.status !== "PENDING" || user.friendship.status === "ACCEPTED";

  return (
    <div key={user.id} className="FriendCard resultRow">
      <div className="userInfo">
        <ProfilePhoto className="profilePhoto" topOffset={"0px"}>
        </ProfilePhoto>
        <h2 className="username">{user.username}</h2>
      </div>
      {
        request == false || request == undefined ?
          <motion.div
            className="motion__button"
            variants={variants}
            whileHover={canAdd ? "bounce" : "none"}
            whileTap={canAdd ? "bounce" : "none"}
          > 
            {canAdd ? <AddButton onAdd={onAdd}></AddButton> : <SentButton></SentButton>}
          </motion.div>
          :
          <AcceptDenyButtons onAccept={onAccept} onDeny={onDeny}></AcceptDenyButtons>
      }
    </div>
  );
};

const AddButton = ({onAdd}) => (
  // <motion.div
  //   className="motion__button"
  //   transition={{ ease: "easeOut", duration: 2 }}
  //   whileTap={{ scale: 0.9 }}
  //   whileHover={{ scale: 0.9 }}
  // > 
  <Button className="button" onClick={() => onAdd()}>Vriend worden</Button>
//  </motion.div>
);

const SentButton = () => (
  // <motion.div
  //   className="motion__button"
  //   transition={{ ease: "easeOut", duration: 2 }}
  // >
  <Button className="button" fill="outline">Gestuurd</Button>  
  // </motion.div>
);

export default FriendCard;
