import { gql } from "urql";

export const friendRequestsQuery = gql`
query {
    friendships(input: {status: PENDING, requestType: INCOMING}) {
        id
        created
        changed
        requestType
        friend {
            id
            username
            email
        }
        status
    }
}`;

export const friendshipsQuery = gql`

query {
    friendships {
        id
        created
        changed
        requestType
        friend {
            id
            username
            email
        }
        status
    }
}`;

export const acceptFriendRequestQuery = gql`
mutation($id: String!) {
    acceptFriendRequest(id: $id) {
        id
        created
        changed
        friend{
            username
        }
        requestType
        status
    }
}`;

export const denyFriendRequestQuery = gql`
    mutation($id: String!) {
        denyFriendRequest(id: $id) {
            id
            created
            changed
            friend{
                username
            }
            requestType
            status
        }
}`;

export const searchUserQuery = `
query($input: String!) {
    users(input: {username: $input}){
        id
        username
        friendship {
            status
            requestType
        }
    }
}`;

export const sendFriendRequestQuery = `
mutation($userId: String!) {
    sendFriendRequest(userid: $userId){
        id
        status
        friend {
            username
            id
        }
    }
}`;
