import {Marker, useMap} from "react-leaflet";
import * as L from "leaflet";
import { useEffect, useState, useRef} from "react";

import orange from "../../../assets/hex_o.svg";
import red from "../../../assets/hex_r.svg";
import green from "../../../assets/hex_g.svg";
import "./customMarker.scss";

const ICON_SIZE = 20;

const LeafIcon = L.Icon.extend({
  options: {
    iconAnchor: [10, 10],
  }
});

const orangeIcon = new LeafIcon({
    iconUrl: orange,
    iconSize: [ICON_SIZE, ICON_SIZE],
      
  }),
  greenIcon = new LeafIcon({
    iconUrl: green,
    iconSize: [ICON_SIZE, ICON_SIZE],
  }),
  redIcon = new LeafIcon({
    iconUrl: red,
    iconSize: [ICON_SIZE, ICON_SIZE],
  });

function CustomMarker(props) {
  const [icon, setIcon] = useState(greenIcon);
  const [correct, setCorrect] = useState(false);
  const [answered, setAnswered] = useState(false);
  let markerRef = useRef();

  const map = useMap();

  useEffect(() => {

    L.Icon.Default.imagePath=orange;

    if(props){
      setAnswered(props.answered);
      setCorrect(props.colour);
      if(props.selected == props.id){
        openMaker();
      }
    }
  }, [props]);

  useEffect(() => {
    if(correct){
      changeIconColor();
    }
  }, [correct]);

  useEffect(() => {
    if(answered){
      changeIconColor();
    }
  }, [answered]);


  /**
   * Opens the Maker
   */
  const openMaker = () =>{
    map.setView(markerRef._latlng, props.zoom);
  };

  /**
   * Sets the color of the icon
   */
  const changeIconColor = () => {    
    switch (correct) {
    case false:
      setIcon((current) => (current = redIcon)); //eslint-disable-line
      break;
    case true:
      setIcon((current) => (current = greenIcon)); //eslint-disable-line
      break;
    default:
      console.log(`Sorry, we are out of ${correct}.`);
    }

    if(!answered){
      setIcon((current) => (current = orangeIcon)); //eslint-disable-line
      return;
    } 

  };

  useEffect(() => {
    changeIconColor();
  }, []);

  return (
    <>
      <Marker
        ref={(r) => {
          markerRef = r;
        }}
        position={props.position}
        eventHandlers={{
          click: () => {
            props.onClick(props.id);
          },
        }}
        icon={icon}
        key={props.colour}
      >
      </Marker>
    </>
  );
}

export default CustomMarker;
