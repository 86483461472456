import {IonPage, IonContent, IonBackButton, IonModal, IonImg} from "@ionic/react";
import "./registrationMapViewer.scss";
import {getRegistrations} from "../../../Queries/RegistrationQuery";
import { useQuery } from "urql";
import Map from "../../organisms/Map";
import CustomMarker from "../../molecules/Marker";
import {useState, useEffect} from "react";
import "swiper/css";
import "swiper/css/pagination";
import { useHistory } from "react-router";

const RegistrationMapViewer = ({ChallengeId}) => {
  const history = useHistory();
  const [mapKey, setVisible] = useState(true);

  const [result] = useQuery({
    query: getRegistrations,
    variables: { id: ChallengeId},
  });

  useEffect(() => {
    setVisible(Math.random());
  },[]);

  const { data, fetching, error } = result;

  const [openModal, setOpenModal] = useState(false);
  const [selectedChallenge, setSelectedChallenge] = useState(null);

  const closeModal = () => {
    setOpenModal(false);
  };

  if (error) return <p>Oh no... {error.message}</p>;
  if (fetching) return <></>;

  return (
    <IonPage className="registrationMapViewer">
      <Map id="challengeMap" mapKey={mapKey} enableCluster={true} Markers={
        data.challenge.registrations.map((element, index) => {
          if(element.location){
            return (
              <CustomMarker
                key={index}
                zoom = {10}  
                position = {[element.location.y, element.location.x]}
                onClick={() => {
                  setOpenModal(true);
                  setSelectedChallenge(element);
                }}
              />);
          }
        })
      }></Map>
      <IonBackButton className="backButton" defaultHref="" onClick={() => {history.push({
        pathname: "/challenge/" + ChallengeId,
      });
      history.go();
      }} />

      <IonModal isOpen={openModal} onDidDismiss={closeModal}
        breakpoints={[0, 0.5, 0.9, 1 ]}
        initialBreakpoint={1} backdropBreakpoint={0.1}>
        <IonContent>
          <IonImg src={selectedChallenge?.imageUrl} className={"registrationModalImage"} />
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default RegistrationMapViewer;
