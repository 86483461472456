import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import Home from "../components/templates/Home";
import { useHistory } from "react-router-dom";

const HomePage = () => {
  const auth = useAuth();
  const history = useHistory();
  

  const loginLocation = {
    pathname: "/login",
  };

  useEffect(() => {
    if(auth.isAuthenticated == false && auth.isLoading == false){
      history.push(loginLocation);
    }
  }, [auth]);

  return <Home />;
};

export default HomePage;
