import Challenge from "../components/templates/Challenge";
// eslint-disable-next-line no-unused-vars
import CompletedChallenge from "../components/templates/ChallengeCompleted/ChallengeCompleted";
import { useQuery } from "urql";
import { useState, useEffect} from "react";
import { matchPath } from "react-router";
// import Spinner from "../components/atoms/spinner";


const ChallengeQuery = `
  query($id: String!) {
    challenge(id: $id){
      id
      typeId
      finished
    }
  }
`;

const challengePage = () => {
  const match = matchPath(window.location.pathname, {
    path: "/challenge/:id",
    exact: true,
    strict: false
  });
  if(match == null)
    return (<></>);

  var id = match.params.id;
  
  // eslint-disable-next-line no-unused-vars
  const [finished, setFinished] = useState("");
  // const [loading, setLoading] = useState(true);

  const [result] = useQuery({
    query: ChallengeQuery,
    variables: { id: id },
  });

  const { data, fetching ,error } = result;

  // const updateLoading = () => {
  //   setLoading(false);
  // };

  useEffect(() => {
    if(data){
      setFinished(data.challenge.finished);
    }
    
  }, [data]);

  if (fetching) return (<></>);

  if (error) return <p>Oh no... {error.message}</p>;

  return <> 
    <Challenge challengeID={id} finished = {finished} onFinished={() => {
      setFinished(true);
    }}/>;
  </>;
};

export default challengePage;
