import { IonPage, IonSearchbar } from "@ionic/react";
import React, {useEffect, useState} from "react";
import { NavbarContext } from "../../../routes";
import { ChallengesQuery } from "../../../Queries/ChallengeQueries";
import { useQuery } from "urql";
import "./ChallengeLobby.scss";
import ChallengeList from "../../organisms/ChallengeList";

const ChallengeLobby = () => {
  const {setShow} = React.useContext(NavbarContext);
  const [searchBar, setSearchBar] = useState();

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <IonPage className="ChallengeLobby">
      <div className="ChallengeLobby__Filter">
        <IonSearchbar value={searchBar} onIonChange={e => setSearchBar(e.detail.value)}></IonSearchbar>
      </div>
      <ChallengeLobbyList searchQueery={searchBar} />
      
    </IonPage>
  );
};

const ChallengeLobbyList = ({searchQueery}) => {
  const [result] = useQuery({
    query: ChallengesQuery,
    variables: { title: searchQueery },
  });
  
  const { data, fetching, error } = result;
  if (error) return <p>Oh no... {error.message}</p>;
  if (fetching) return <></>;

  return(
    <>
      <ChallengeList type={"join"} data={data.challenges} showProgress={false} />
    </>

  );
};

export default ChallengeLobby;
