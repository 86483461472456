import { IonList, IonContent, IonCard } from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { duplicateOutline } from "ionicons/icons";
import ChallengeCard from "../../molecules/ChallengeCard";
import JoinChallengeCard from "../../molecules/JoinChallengeCard";
import "./ChallengeList";
import { motion } from "framer-motion";
import { useHistory,} from "react-router-dom";

import "./challengelist.scss";

const ChallengeList = (props) => {
  const history = useHistory();
  
  const Location = {
    pathname: "/challenges",
  };
  
  const checkregs = (questions) => {
    var started = false;
    for (var i = 0; i < questions.length; i++) {
      if (questions[i].registrations.length > 0){
        started = true;
        break;
      }
    }
    
    if(props.showProgress == false) return false;
    return started;
  };

  const joinButton = () => {
    if(props.type != "join"){
      return(
        <IonCard key="999999" className="challengeCard JoinCard" onclick={() => {history.push(Location); history.go();} }>
          <div className="joinCard__content">
            <motion.div
              whileTap={{scale: 0.9}}
            >
              <IonIcon
                className="JoinCard__Icon"
                icon={duplicateOutline}
              />  
            </motion.div>
            <h1>Challenges zoeken</h1>   
          </div>
        </IonCard>
      );
    }  
  };

  return (
    <IonContent>
      <IonList>
        {props.data.map((challenge) => {
          if(props.type == "join"){
            return (
              <JoinChallengeCard
                key={challenge.id}
                challenge={challenge}
                finished={challenge.finished}
                started = {checkregs(challenge.questions)}
                active = {challenge.active} 
              ></JoinChallengeCard>
            );
          } else {
            return (
              <ChallengeCard
                key={challenge.id}
                challenge={challenge}
                finished={challenge.finished}
                started = {checkregs(challenge.questions)}
                active = {challenge.active} 
              ></ChallengeCard>
            );
          }
        }
        )}
        {
          joinButton()
        }
      </IonList>
    </IonContent>
  );
};

export default ChallengeList;
