import { useEffect } from "react";
import { useAuth } from "react-oidc-context";  
  
  
const logoutPage = () => {
  const auth = useAuth();

  useEffect(()=>{
    auth.clearStaleState();  
    auth.signoutRedirect();
  }, []);

  return (
    <div>

    </div>
  );
};

export default logoutPage;
