import { matchPath } from "react-router";
import RegistrationMapViewer from "../components/templates/registrationMapViewer";

const RegistrationsPage = () => {
  const match = matchPath(window.location.pathname, {
    path: "/challenge/:id/registraties/map",
    exact: true,
    strict: false
  });
  if(match == null)
    return (<></>);
  
  const id = match.params.id;

  return <RegistrationMapViewer ChallengeId={id}/>;
  

};

export default RegistrationsPage;
