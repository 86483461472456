import {
  IonRow
} from "@ionic/react";
import bee from "../../../images/colored_bee.svg";
import "./profilePhoto.scss";

const ProfilePhoto = ({ children, topOffset }) => {
  return (
    <IonRow className="profilePhoto">
      {children}
      <div className="profilepicture-container" style={{top: topOffset != undefined ? topOffset : "9.5vh"}}>
        <img
          className="profilepicture"
          src={bee}
        />
        {" "}
        <svg>
          <defs>
            <clipPath id="hex-path" clipPathUnits="objectBoundingBox">
              <path d="M0.037,0.624 C-0.003,0.547,-0.003,0.453,0.037,0.377 L0.173,0.124 C0.214,0.047,0.289,0,0.371,0 H0.643 C0.724,0,0.8,0.047,0.84,0.124 L0.976,0.377 C1,0.453,1,0.547,0.976,0.624 L0.84,0.877 C0.8,0.953,0.724,1,0.643,1 H0.371 C0.289,1,0.214,0.953,0.173,0.877 L0.037,0.624"></path>
            </clipPath>
          </defs>
        </svg>
      </div>
    </IonRow>
  );
};

export default ProfilePhoto;
