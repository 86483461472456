import { IonPage,  } from "@ionic/react";
import "./registrationViewer.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import {getRegistrations} from "../../../Queries/RegistrationQuery";
import { useQuery } from "urql";
import "swiper/css";
import "swiper/css/pagination";
import Button from "../../atoms/Button";

const RegistrationViewer = ({ChallengeId}) => {

  const [result] = useQuery({
    query: getRegistrations,
    variables: { id: ChallengeId},
  });

  const { data, fetching, error } = result;

  if (error) return <p>Oh no... {error.message}</p>;
  if (fetching) return <></>;

  return (
    <IonPage className="registrationViewer">
      <Button className="regButton" href={"challenge/" + ChallengeId}>Back</Button>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="registrationSwiper"
      >
        {
          data.challenge.registrations.map((element, index) => {
            return (
              <SwiperSlide key={index} className={"regSlide"} >
                
                <img
                  key={index}
                  src={element.imageUrl}
                ></img>
               
              </SwiperSlide>);
          })
          
        }
      </Swiper>
    </IonPage>
  );
};

export default RegistrationViewer;
