/* eslint-disable no-undef */
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, createGesture } from "@ionic/react";
import React, { useEffect } from "react";
import "./validatieCard.scss";

const ValidatieCard = ({ onMatch, rotation, selected, correct, index, registrationId, value, imageUrl }) => {
  const card = React.useRef();
  const overlay = React.useRef();

  useEffect(() => {
    const style = card.current.style;
    const overlayStyle = overlay.current.style;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    if(selected == index){
      style.transition  = "0.6s ease-out";

      if(correct == "") return;

      if(correct == "true"){
        style.transform = `translateX(${windowWidth * 1.5}px)`;
        overlayStyle.backgroundColor = "#00FF00";
        overlayStyle.display = "block";
        overlayStyle.opacity = 0.4;
        onMatch({id: registrationId, value: "TRUE"});
      }

      if(correct == "false"){
        overlayStyle.display = "block";
        overlayStyle.backgroundColor = "#FF0000";
        overlayStyle.opacity = 0.4;
        style.transform = `translateX(-${windowWidth * 1.5}px)`;
        onMatch({id: registrationId, value: "FALSE"});
      }

      if(correct == "skip"){
        overlayStyle.display = "block";
        overlayStyle.backgroundColor = "#000000";
        overlayStyle.opacity = 0.4;
        style.transform = `translateY(-${windowHeight * 1.5}px)`;
        onMatch({id: registrationId, value: "PASS"});
      }
    }
  }, [correct]);

  useEffect(() => {
    const style = card.current.style;
    const overlayStyle = overlay.current.style;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    
    style.transform = `rotate(${rotation}deg)`;

    const optionsX = {
      el: card.current,
      gestureName: "swipe-ltr",
      direction: "x",

      onStart: () => {
        style.transition = "none";
      },

      onMove: (ev) => {
        style.transform = `translateX(${ev.deltaX}px) rotate(${
          ev.deltaX / 15
        }deg)`;

        if (ev.deltaX > windowWidth / 6) {
          overlayStyle.display = "block";
          overlayStyle.backgroundColor = "#00FF00";
          overlayStyle.opacity = ev.deltaX / windowWidth;
        } else if (ev.deltaX < -windowWidth / 6) {
          overlayStyle.display = "block";
          overlayStyle.backgroundColor = "#FF0000";
          overlayStyle.opacity = ev.deltaX / -windowWidth;
        } else {
          overlayStyle.display = "none";
        }
      },

      onEnd: (ev) => {
        style.transition = overlayStyle.transition = "0.3s ease-out";
        if (ev.deltaX > windowWidth / 4) {
          style.transform = `translateX(${windowWidth * 1.5}px)`;
          onMatch({id: registrationId, value: "TRUE"});
        } else if (ev.deltaX < -windowWidth / 4) {
          style.transform = `translateX(-${windowWidth * 1.5}px)`;
          onMatch({id: registrationId, value: "FALSE"});
        } else {
          style.transform = "";
          overlayStyle.display = "none";
        }
      },
    };

    const optionsY = {
      el: card.current,
      gestureName: "swipe-up",
      direction: "y",

      onStart: () => {
        style.transition = "none";
      },

      onMove: (ev) => {
        if(ev.deltaY < 0){
          style.transform = `translateY(${ev.deltaY}px) rotate(${
            ev.deltaY / 15
          }deg)`;
        }

        if (ev.deltaY < -windowHeight / 6) {
          overlayStyle.display = "block";
          overlayStyle.backgroundColor = "#000000";
          overlayStyle.opacity = ev.deltaY / -windowHeight;
        } else {
          overlayStyle.display = "none";
        }
      },

      onEnd: (ev) => {
        style.transition = overlayStyle.transition = "0.3s ease-out";
        if (ev.deltaY < -windowHeight / 4) {
          style.transform = `translateY(-${windowHeight * 2}px)`;
          onMatch({id: registrationId, value: "PASS"});
        } else {
          style.transform = "";
          overlayStyle.display = "none";
        }
      },
    };
    
    const gestureY = createGesture(optionsY);
    const gestureX = createGesture(optionsX);
    gestureX.enable();
    gestureY.enable();
    
  }, []);

  return (
    <IonCard className="validatieCard" ref={card}>
      <div className="validatieCard__Overlay" ref={overlay}></div>
      <IonCardHeader>  
        <IonCardTitle className="Title">Zie je het volgende op deze foto?</IonCardTitle>
        <IonCardSubtitle>{value}</IonCardSubtitle>
      </IonCardHeader>

      <IonCardContent className="validatieCard__content">
        <img src={imageUrl}></img>
      </IonCardContent>
    </IonCard>
  );
};

export default ValidatieCard;
