/* eslint-disable no-unused-vars */
import { IonIcon, useIonToast } from "@ionic/react";
import {acceptFriendRequestQuery, denyFriendRequestQuery, friendRequestsQuery} from "../../../Queries/FriendQueries";
import { useQuery, useMutation } from "urql";
import bee from "../../../images/colored_bee.svg";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import Spinner from "../../atoms/spinner/spinner";
import "./friendRequest.scss";
import AcceptDenyButtons from "../../molecules/AcceptDenyButtons";
import FriendCard from "../../molecules/FriendCard";
import CenteredText from "../../atoms/CenteredText";

const FriendRequests = () => {
  const [present, dismiss] = useIonToast();
  const [acceptFriendMutation, acceptFriendRequest] = useMutation(acceptFriendRequestQuery);
  const [denyFriendMutation, denyFriendRequest] = useMutation(denyFriendRequestQuery);

  const [result] = useQuery({
    query: friendRequestsQuery,
    requestPolicy: "cache-and-network"
  });
  
  const { data, fetching, error } = result;

  if (error) return <p>Oh no... {error.message}</p>;
  if (fetching || data == undefined) return <></>;

  return (
    <>
      {data.friendships.length > 0 ?
        <div className="request-list">
          {data.friendships.map((friendship) => (
            <FriendCard
              key={friendship.id}
              user={friendship.friend}
              request
              onAccept={() => {
                acceptFriendRequest({id: friendship.id});
                present({message: "Vriendschap verzoek geaccepteerd!", duration: 3000});
              }}
              onDeny={() => {
                denyFriendRequest({id: friendship.id});
                present({message: "Vriendschap verzoek afgewezen!", duration: 3000});
              }}
            ></FriendCard>
          ))}
        </div>
        :
        <CenteredText>Je hebt geen vriendschapsverzoeken</CenteredText>}
    </>
  );
};

export default FriendRequests;
