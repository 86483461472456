import {IonCard, IonCardHeader, IonCardTitle, IonImg } from "@ionic/react";
import "./JoinChallengeCard.scss";

const JoinChallengeCard = ({challenge}) => {

  return (
    <IonCard className={"challengeCard "} button={true} href={"/challenge/" + challenge.id + "/join"}>
      <IonCardHeader class="header">
        <IonCardTitle class="challengeTitle">
          {challenge.name}
        </IonCardTitle>
      </IonCardHeader>
      <IonImg class="image imgCol" src={challenge.imageUrl} />
      
    </IonCard>
  );
};

export default JoinChallengeCard;
