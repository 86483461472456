/* eslint-disable no-undef */
import { IonPage } from "@ionic/react";
import MarkdownContent from "../../atoms/MarkdownContent";
import "./info.scss";

const Info = () => {
  return (
    <IonPage className="info">
      <div>
        <MarkdownContent content={`**Welkom!** Je kijkt nu naar de allereerste versie van BeHive. 

BeHive is dé app om al spelenderwijs de groene leefomgeving te verkennen. De app wordt opgebouwd uit challenges die het leuk maken om bezig te zijn met thema’s als groen, water, natuur, tuin, flora&fauna en een schone leefomgeving. Iedereen draagt tijdens het spelen van de app z’n steentje bij. Het is de ideale combinatie tussen spelen, leren, verkennen en onderzoeken. Je levert een bijdrage aan de omgeving én aan onderzoek. De app is voor iedereen! Meer info over BeHive kun je vinden op [www.behive.nl](https://www.behive.nl "www.behive.nl").

**In ontwikkeling**
Op dit moment zijn we hard aan het werk voor het uitbreiden van het aantal challenges. Wil je op de hoogte gehouden worden van de ontwikkelingen? Laat ons dit dan weten via het [contactformulier](https://www.behive.nl/#contact "contactformulier").

**Eigen challenge**
Wil je zelf ook een challenge lanceren in deze app? Neem dan [contact](https://www.behive.nl/#contact "contactformulier") met ons op voor de mogelijkheden. 

**Vragen**
Heb je nog vragen, ideeën, verbeterpunten of bugs voor ons? Neem dan contact op met ons via het [contactformulier](https://www.behive.nl/#contact "contactformulier").`} />
      </div>
     
    </IonPage>
  );
};

export default Info;
