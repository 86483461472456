/* eslint-disable no-unused-vars */
import { IonPage, IonButton, IonBackButton, IonSegment, IonSegmentButton, IonLabel } from "@ionic/react";
import {useState} from "react";
import {NavbarContext} from "../../../routes";
import React, {useEffect} from "react";
import FriendRequests from "../../organisms/FriendRequests/FriendRequests";
import FriendSearchResults from "../../organisms/FriendSearchResults";
import "./friends.scss";

const Friends = () => {
  const [pageType, setPageType] = useState("request");
  const {setShow} = React.useContext(NavbarContext);

  useEffect(() => {
    setShow(false);
  }, []);

  return (
    <IonPage className="friendsPage">
      <div className="friends__header">
        <div>
          <IonBackButton className="backButton" defaultHref="/profile" />
        </div>
        
        <div className="headerText">
          <h2>Vrienden</h2>
        </div>
        <div>
        </div>
      </div>

      <div className="friends__container">
        <IonSegment class="segment" mode="ios" value={pageType} onIonChange={e => setPageType(e.detail.value)}>
          <IonSegmentButton class="segment__button" value="request">
            <IonLabel class="label">Vriend verzoeken</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton class="segment__button" value="result">
            <IonLabel class="label">Vriend toevoegen</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <div className="results">
          
          {pageType === "request" ? 
            <FriendRequests/> : 
            <FriendSearchResults/>}
        </div>
      </div>
    </IonPage>
  );
};

export default Friends;
