import {gql} from "urql";

export const UserQuery = gql`
query {
  currentUser {
    username
    uniqueUsername
    experiencePoints
    challenges {
        id
        name
        description
        finished
        active
        typeId
        imageUrl
        questions{
        registrations{
            id
        }
        }
    }
  }
}
`;
