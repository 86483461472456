import React from "react";
import {Routes} from "../../../routes";
import "./body.scss";

const layout = ({ children }) => {
  return (
    <>
      <div className="app_body">{children}</div>
      {<Routes />}
    </>
  );
};

export default React.memo(layout);
