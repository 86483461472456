import {MapContainer, TileLayer, LayersControl, useMapEvents, WMSTileLayer} from "react-leaflet";
import { FullscreenControl } from "react-leaflet-fullscreen";
import { useEffect, useState } from "react";
import Locate from "leaflet.locatecontrol";
import "./map.scss";
import MarkerClusterGroup from "react-leaflet-markercluster";

const {BaseLayer} = LayersControl;
const BRTA_ATTRIBUTION = "pdok.nl";

const dataset = {
  "achtergrond": "https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0/standaard/EPSG:3857/{z}/{x}/{y}.png",
  "luchtfoto": "https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0/Actueel_orthoHR/EPSG:3857/{z}/{x}/{y}.jpeg",
  "floriade": "https://api.ellipsis-drive.com/v2/ogc/wms/37e39bb1-5865-4931-ae1e-18257dfd9cae"
};

function LocateUser() {
  const map = useMapEvents( { } );

  useEffect(() => {
    let lc = new Locate({
      position: "topright",
      icon: "geoLocate_icon",
      setView: "once",
    });
    lc.addTo(map);
  }, [map]);

  return null;
}

function LayerControl(props, index) {
  return (
    <LayersControl.Overlay checked name={props.name} key={2 + index}>
      <WMSTileLayer
        key={2 + index}
        layers={props.layerId}
        url={props.url}
        opacity={props.opacity}
        format={props.format}
        minZoom={props.minZoom}
        maxZoom={props.maxZoom}
      />
    </LayersControl.Overlay>);
}

function CustomLayer(props) {
  var config;

  if (props.configuration === undefined || props.configuration.length === 0){
    return null;
  } else {
    config = JSON.parse(props.configuration);
    return config.mapLayers.map((layer, index) => LayerControl(layer, index));
  }
}

const Map = (props) => {
  const [mapKey, setMapKey] = useState();

  useEffect(() => {
    setMapKey("map_page");
  }, [props]);

  return (
    <div className="mapContainer">
      <MapContainer
        key={mapKey}
        tap={false}
        center={[52, 5]}
        zoom={8}
        maxZoom={25}
        className="map"
        id="map"
      >
        <LocateUser />

        <LayersControl>
          <BaseLayer checked name="Standaard">
            <TileLayer
              key={0}
              attribution={BRTA_ATTRIBUTION}
              url= {dataset.achtergrond}
              minZoom={4}
              maxZoom={19}
            />
          </BaseLayer>

          <BaseLayer name="Luchtfoto">
            <TileLayer
              key={1}
              attribution={BRTA_ATTRIBUTION}
              url={dataset.luchtfoto}
              minZoom={4}
              maxZoom={19}
            />
          </BaseLayer>

          <CustomLayer configuration={props.configuration} />
        </LayersControl>

        <FullscreenControl
          content= "<img style='padding: 6px;' src='https://www.svgrepo.com/show/395520/size-fullscreen.svg'></img>"
        />
        {props.enableCluster ? <MarkerClusterGroup>{props.Markers}</MarkerClusterGroup>:<>{props.Markers}</>}

      </MapContainer>
    </div>
  );
};

export default Map;
