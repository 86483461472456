import { 
  IonApp, 
  IonRouterOutlet, 
  IonTabs, 
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonIcon
} from "@ionic/react";

import HomePage from "./pages/homePage";
import ProfilePage from "./pages/profilePage";
import MapPage from "./pages/mapPage";
import LoginPage from "./pages/loginPage";
import challengePage from "./pages/challengePage";
import logoutPage from "./pages/logoutPage";
import MapQuiz from "./components/templates/MapQuiz";
import Quiz from "./components/templates/Quiz";
import InfoPage from "./pages/infoPage";
import ValidatiePage from "./pages/ValidatiePage";
import ChallengeListPage from "./pages/challengeListPage";
import FriendsPage from "./pages/friendsPage";
import PhotoPage from "./pages/photoPage";
import ChallengeJoinPage from "./pages/challengeJoinPage";
import FriendsInfoPage from "./pages/friendsInfoPage";

import { Route, useHistory, Redirect } from "react-router-dom";
import React, { useEffect } from "react";
import { useState } from "react";

import { bookOutline, mapOutline, checkboxOutline, helpOutline } from "ionicons/icons";
import CompletedChallenge from "./components/templates/ChallengeCompleted"; 
import { IonReactRouter } from "@ionic/react-router"; 
import { useAuth } from "react-oidc-context";

import RegistrationsPage from "./pages/registrationPage";
import RegistrationsMapPage from "./pages/registrationMapPage";


import { createAnimation } from "@ionic/react";

const animationBuilder = (baseEl, opts) => {
  const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo("opacity", 0, 1)
    .duration(600);

  const leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo("opacity", 1, 0)
    .duration(150);

  const animation = createAnimation()
    .addAnimation(enteringAnimation)
    .addAnimation(leavingAnimation);

  return animation;
};

export const  NavbarContext = React.createContext(true);


export const Routes = () => {
  const [show, setShow] = useState(false);
  const [Authenticated, setAuthenticated] = useState(true);
  const auth = useAuth();

  useEffect(() => {
    if(auth.isAuthenticated == false && auth.isLoading == false){
      setAuthenticated(false);
    }else{
      setAuthenticated(true);
    }
  }, [auth]);


  return(
    
    <NavbarContext.Provider value={{show, setShow}}>
      <IonApp>
        <IonReactRouter>
          <IonTabs>
       
            <IonRouterOutlet animation={animationBuilder}>
              <Route path="/challenge/:id" component={challengePage} />
              <Route path="/challenge/:id/done" component={CompletedChallenge}/>
              <Route path="/challenge/:id/mapquiz" component={MapQuiz} />
              <Route path="/challenge/:id/quiz" component={Quiz} />
              <Route path="/challenge/:id/photo" component={PhotoPage} />
                  
              <Route path="/friendsinfo/:id">
                {Authenticated ? <FriendsInfoPage/> : <ProtectedRoute />}
              </Route>  

              <Route path="/challenge/:id/join" component={CompletedChallenge}>
                {Authenticated ? <ChallengeJoinPage /> : <ProtectedRoute />}
              </Route>

              <Route  path="/challenge/:id/registraties/map">
                {Authenticated ? <RegistrationsMapPage /> : <ProtectedRoute />}
              </Route>
              <Route  path="/challenge/:id/registraties/photo">
                {Authenticated ? <RegistrationsPage /> : <ProtectedRoute />}
              </Route>

              <Route path="/logout" component={logoutPage} />
              <Route path="/login" component={LoginPage} />

              <Route path="/validatie">
                {Authenticated ? <ValidatiePage /> : <ProtectedRoute />}
              </Route>
              <Route path="/challenges">
                {Authenticated ? <ChallengeListPage /> : <ProtectedRoute />}
              </Route>  
              <Route path="/home">
                {Authenticated ? <HomePage/> : <ProtectedRoute />}
              </Route> 
              <Route path="/profile">
                {Authenticated ? <ProfilePage/> : <ProtectedRoute />}
              </Route> 
              <Route path="/map">
                {Authenticated ? <MapPage/> : <ProtectedRoute />}
              </Route> 
              <Route path="/info">
                {Authenticated ? <InfoPage/> : <ProtectedRoute />}
              </Route>  
              <Route path="/friends">
                {Authenticated ? <FriendsPage/> : <ProtectedRoute />}
              </Route>  

              {Authenticated ? <Route render={() => <Redirect to="/home" />} /> : <Route render={() => <Redirect to="/login" />} />} 
            </IonRouterOutlet>
          
            <IonTabBar slot="bottom" hidden={!show}>
              <IonTabButton tab="Challenges" href="/home">
                <IonIcon icon={bookOutline}/>
                <IonLabel>Challenges</IonLabel>
              </IonTabButton>
              <IonTabButton tab="Map" href="/map">
                <IonIcon icon={mapOutline}/>
                <IonLabel>Kaart</IonLabel>
              </IonTabButton>
              <IonTabButton tab="validatie" href="/validatie">
                <IonIcon icon={checkboxOutline}/>
                <IonLabel>Validatie</IonLabel>
              </IonTabButton>
              <IonTabButton tab="info" href="/info">
                <IonIcon icon={helpOutline}/>
                <IonLabel>info</IonLabel>
              </IonTabButton>
            </IonTabBar>

          </IonTabs>
        </IonReactRouter>
      </IonApp>
    </NavbarContext.Provider>
    
  );
};

export const ProtectedRoute = () => {
  const auth = useAuth();
  const history = useHistory();
  

  const loginLocation = {
    pathname: "/login",
  };

  useEffect(() => {
    if(auth.isAuthenticated == false && auth.isLoading == false){
      history.push(loginLocation);
    }
  }, [auth]);

  return (
    <>

    </>
  );
};
