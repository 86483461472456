import {
  IonPage,
  IonModal,
  IonContent,
  IonButton,
  IonImg,
  useIonViewWillLeave
} from "@ionic/react";

import React, { useState, useEffect} from "react";
import Map from "../components/organisms/Map";
import CustomMarker from "../components/molecules/Marker";
import { useQuery } from "urql";
// import Spinner from "../components/atoms/spinner";
import MarkdownContent from "../components/atoms/MarkdownContent";
import {NavbarContext} from "../routes";

import { AllChallengeQuery } from "../Queries/ChallengeQueries";


  


const MapPage = () => {
  const [result] = useQuery({
    query: AllChallengeQuery
  });

  const {setShow} = React.useContext(NavbarContext);
  useEffect(() => {
    setShow(true);
  });

  useIonViewWillLeave(() => {
    setOpen(false);
  }, []);

  const [mapKey, setVisible] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedChallenge, setSelectedChallenge] = useState(null);

  const closeModal = () => {
    setOpen(false);
  };

  const customMarkers = () => {
    return data.challenges.filter(challenge => challenge.location != null).map((challenge) => {
      return (
        <CustomMarker
          key={challenge.id}
          zoom={10}
          position={[challenge.location.y, challenge.location.x]}
          onClick={() => {
            setOpen(true);
            setSelectedChallenge(challenge);
          }}
        ></CustomMarker>
      );
    });
  };

  useEffect(() => {
    setVisible(Math.random());
  },[]);

  const { data,fetching, error } = result;
  if (error) return <p>Oh no... {error.message}</p>;
  if (fetching) return (<></>);

  return (
    <IonPage>
      <div className="Challenge__Map">
        <Map id="challengeMap" mapKey={mapKey} Markers={customMarkers()}></Map>
      </div>
      <IonModal isOpen={open} onDidDismiss={closeModal}
        breakpoints={[0, 0.3, 0.5, 1 ]}
        initialBreakpoint={0.5} backdropBreakpoint={0.1}>
        <IonContent>
          <IonImg src={selectedChallenge?.imageUrl} className={"challengeModalImage"} />
          <div className={"challengeModalContent"}>
            <h2 key={selectedChallenge?.id}>{selectedChallenge?.name}</h2>
            <MarkdownContent content={selectedChallenge?.description}/>
            <IonButton className={"defaultButton centeredButton"}
              routerLink={"challenge/"+ selectedChallenge?.id}
            >
              Ga naar challenge
            </IonButton>
          </div>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default MapPage;
