import { IonPage, IonBackButton } from "@ionic/react";
import Button from "../../atoms/Button";
import { useHistory } from "react-router-dom";
import { NavbarContext } from "../../../routes";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { Doughnut } from "react-chartjs-2";
import {Chart, ArcElement, Tooltip} from "chart.js";
Chart.register(ArcElement, Tooltip);
import MarkdownContent from "../../atoms/MarkdownContent";
import "./challengeCompleted.scss";
import { matchPath } from "react-router";

const options = {
  responsive: true,
  maintainAspectRatio: false,
};

const ChallengeCompleted = ({location}) => {
  const match = matchPath(window.location.pathname, {
    path: "/challenge/:id/done",
    exact: true,
    strict: false
  });
  if(match == null)
    return (<></>);
  
  const id = match.params.id;
  const auth = useAuth();
  const {setShow} = React.useContext(NavbarContext);
  const [qdata, setData] = useState("");
  const history = useHistory();
  const [type, setType] = useState("");

  useEffect(() => {
    if(!auth.isAuthenticated && !auth.isLoading){
      setShow(false);
    }else{
      setShow(true);
    }
  }, [auth]);

  useEffect(() => {
    if(location){
      setData(location.state.questions);
      
      setType(getTypeURI( location.state.challenge.challenge.typeId));
    }
  }, []);

  /**
   * Gets the type of the challenge
   * @param {string} type 
   * @returns The type of the challenge
   */
  const getTypeURI = (type) => {
    switch (type) {
    case 1:
      return "mapquiz";
    case 2:
      return "quiz";
    }
  };
  
  /**
   * Gets the image if there is one
   * @returns {string}
   */
  const getImage = () => {
    if(typeof location.state !== "undefined") return location.state.challenge.challenge.imageUrl;
    return "";
  };

  /**
   * ChartJs graph
   * @returns The graph
   */
  const data = () => {
    return {
      name: "quizCompleted",
      labels: ["Goed", "Fout"],
      datasets: [
        {
          label: "# antwoorden",
          data: [correctAnswers(), qdata.length - correctAnswers()],
          backgroundColor: [
            "rgba(90, 250, 65, 0.8)",
            "rgba(237, 69, 57, 0.8)",
          ],
          borderColor: [
            "rgba(90, 250, 65, 1)",
            "rgba(237, 69, 57, 1)",
          ],
          borderWidth: 1,
        },
      ],
      
    };
  };

  /**
   * Calculates the amount of correct answers
   * @returns The amount of correct answers
   */
  const correctAnswers = () => {
    let count = 0;

    for (let i = 0; i < qdata.length; i++){
      if(qdata[i].registrations[0].xp > 0 && qdata[i].registrations[0] !== "undefined")
        count++;
    }
    return count;
  };

  return (
    <IonPage className="challengeCompleted">
      <div className="image__header">
        <div className="challenge__image" style={{backgroundImage:`linear-gradient(180deg, rgba(244, 241, 241, 0) 50.29%, rgba(6, 6, 6, 0.6) 100%), url("${getImage()}")`}}>
          <div className="challenge__navigation">
            <div className="backArrow">
              <IonBackButton className="backButton" defaultHref="/home" />
            </div>
          </div>    
        </div>
      </div>
      
      <div className="Challenge__info">
        <div className="markdown">
          <h1>Je hebt de challenge voltooid</h1>
          <MarkdownContent content={"Leuk dat je de challenge hebt gespeeld. Meer challenges spelen? Ga naar het [hoofdscherm](https://webapp-acc.behive.nl/home) van de app."}/>
          
        </div>
       
        <div className="challengeStats">
          <span>Je hebt {correctAnswers()} van de {qdata.length} vragen goed beantwoord!</span>
          <div className="challengeStats__chart">
            
            <Doughnut  data={data()} options={options}/>
          </div>
        
        </div>
        
      </div>

      <div className="navButtons">
        
        
        {auth.isAuthenticated && <Button className="backButton" onClick={() =>{
          let questionLocation = {
            pathname:  "/challenge/" + id +"/"+ type,
            state: {
              id: id,
              questions: qdata,
              challenge: location.state.challenge
            }
          };
          history.push(questionLocation);
          history.go(); 
        }}>Naar de vragen</Button>}

      </div>
      
    </IonPage>
  );
};

export default ChallengeCompleted;
