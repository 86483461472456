import { useAuth } from "react-oidc-context";
import Button from "../../atoms/Button";
import Spinner from "../../atoms/spinner";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import "./loginWidget.scss";

function LoginWidget() {
  const auth = useAuth();
  const history = useHistory();
  const defaultLocation = {
    pathname: "/home",
  };

  useEffect(() => {
    if(auth.isAuthenticated){
      history.push(defaultLocation);
    }
  }, []);

  switch (auth.activeNavigator) {
  case "signinSilent":
    return <div>Signing you in...</div>;
  case "signoutRedirect":
    return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <Spinner/>;
  }

  if (auth.error) {
    const storedState = sessionStorage.getItem(
      `oidc.user:${auth.settings.authority}:${auth.settings.client_id}`,
    );
    if(storedState != null){
      history.push(defaultLocation);
    }
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    return (
      <></>
    );
  }

  return (
    <div className="loginWidget">
      <Button className="loginButton" onClick={auth.signinRedirect}>Start</Button>
    </div>
  );
}

export default LoginWidget;
