import {
  IonPopover,
  IonItem
} from "@ionic/react";
import React from "react";
import "./quizfeedbackpopup.scss";

const QuizFeedbackPopup = ({showPopover, setShowPopover, correct, correctAnswers, closeAndNext}) => {
  return (<IonPopover isOpen={showPopover} onDidDismiss={() => setShowPopover(false)}>

    {correct && <div className="popover__content correct">
      <h1>Geweldig, dat was het goede antwoord!</h1>
    </div>}

    {!correct && <div className="popover__content wrong">
      <h1>Jammer, je had de vraag niet goed.</h1>
      <h4>Het goede antwoord was: &quot;{correctAnswers[0]}&quot;.</h4>
    </div>}


    <IonItem button className="popover__button" onclick={() => {
      closeAndNext();
    }}>
      volgende
    </IonItem>

  </IonPopover>);
};

export default QuizFeedbackPopup;
