import {
  IonGrid,
  IonRow,
  IonProgressBar,
} from "@ionic/react";
import "./profileInformation.scss";


const ProfileInformation = (props) => {
  
  //Current xp of the user
  const currentUserXp = props.data.currentUser.experiencePoints;
  
  //Amount of xp needed for level 1
  const baseXp = 75;

  /**
   * Calculates your level based on your experience
   * @param {int} experience 
   * @returns The level of the player
   */
  const calculateLevel = (experience) => {
    let level = 1;
    for(let i = baseXp; i < experience; i = i * 1.25) {
      if(i <= experience){
        level++;
      }
    } 
    return level;
  };

  /**
   * Calculates the amount of xp needed for a certain level
   * @param {int} level 
   * @returns The amount of xp the player needs for a certain level
   */
  const calculateExperience = (level) => {
    let experience = baseXp ;
    for(let i = 1; i < level; i++){
      experience = experience * 1.25;
    }
    return experience;
  };

  //The current lvel
  const currentLevel = calculateLevel(currentUserXp);

  //The next level 
  const nextLevel = calculateLevel(currentUserXp) + 1;

  //The amount of xp needed for the next level
  const nextLevelxp = calculateExperience(nextLevel) - currentUserXp;

  return (<>
    <IonGrid className="profileInfo">
      <IonRow className="progressBar">
        <div className="hexagon">
          {calculateLevel(currentUserXp)}
        </div>
        <div>
          <div className="ion-stroke">
            <IonProgressBar className="xpBar"
              value={currentLevel / nextLevel}>
            </IonProgressBar>
          </div>
        </div>
      </IonRow>
      <p>nog {Math.round(nextLevelxp)} xp tot het volgende level</p>
    </IonGrid>
  </>
  );
};

export default ProfileInformation;
