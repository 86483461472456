import {IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonImg, IonIcon} from "@ionic/react";
import { checkmarkCircleOutline, rocketOutline, timeOutline } from "ionicons/icons";
import "./challengeCard.scss";

const ChallengeCard = ({started, active, challenge}) => {

  const isQuiz = (typeId) => {
    switch(typeId){
    case 1:
      return true;
    case 2:
      return true;
    default:
      return false;
    }

  };

  const Location = {
    pathname: "/challenge/" + challenge.id,
  };

  return (
    <IonCard key={challenge.id} href={Location.pathname} className={"challengeCard " + (active ? "" : "grayscale ") + (started ? "started" : "")} button={true}>
      <IonCardHeader class="header">
        {
          (challenge.finished && active && isQuiz(challenge.typeId)) && 
          <IonCardSubtitle class="challengeSubtitle">
            Gespeeld <IonIcon icon={checkmarkCircleOutline} size="small"></IonIcon>
          </IonCardSubtitle>
        }
        {
          (started && active && !challenge.finished) && 
          <IonCardSubtitle class="challengeSubtitle orangeText">
            Aan het spelen <IonIcon icon={rocketOutline} size="small"></IonIcon>
          </IonCardSubtitle>
        }
        {
          !active &&
          <IonCardSubtitle class="challengeSubtitle">
            Afgelopen <IonIcon icon={timeOutline} size="small"></IonIcon>
          </IonCardSubtitle>
        }
        
        <IonCardTitle class="challengeTitle">
          {challenge.name}
        </IonCardTitle>
      </IonCardHeader>
      <IonImg class="image imgCol" src={challenge.imageUrl} />
      
    </IonCard>
  );
};

export default ChallengeCard;
