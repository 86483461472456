import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonBackButton,
  IonPopover,
  IonItem,
  IonIcon,
  IonItemOption
} from "@ionic/react";
import cog from "../../../images/cog.svg";
import { useState } from "react";
import { useQuery } from "urql";
import "./Profile.scss";
import ProfilePhoto from "../../molecules/ProfilePhoto";
import Friendlist from "../../organisms/FriendList";
import {addCircleOutline} from "ionicons/icons";
import { UserQuery } from "../../../Queries/UserQueries";
import { motion } from "framer-motion";
import ProfileInformation from "../../molecules/ProfileInformation";


const Profile = () => {
  const [result, reexecuteQuery] = useQuery({//eslint-disable-line
    query: UserQuery,
    requestPolicy: "cache-and-network",
  });
  const { data, fetching, error } = result;
  const [showPopover, setShowPopover] = useState(false);

  if (fetching || data == undefined) return (<></>);
  if (error) return <p>Oh no... {error.message}</p>;

  /**
   * Shows the settings popup onClick
   */
  const settingOnCLick = () => {
    setShowPopover(!showPopover);
  };

  let finishedChallenges = data.currentUser.challenges.filter(challenge => challenge.finished == true).length;

  return (
    <>
      <IonPopover
        isOpen={showPopover}
        onDidDismiss={() => setShowPopover(false)}
      >
        <IonContent className="question__popover">
          <IonItem
            button
            href="/logout"
          >
          uitloggen
          </IonItem>
       
          <IonItem
            button
            onclick={() => {
              settingOnCLick();
            }}
          >
          annuleren
          </IonItem>
        </IonContent>
      </IonPopover>

      <IonPage>
        <IonContent scrollX={true} fullscreen={true}>
          <IonGrid className="profileGrid">
            <IonGrid className="navigationGrid">
              <IonCol className="navigation">
                <IonBackButton className="backButton" defaultHref="/home" />
              </IonCol>
            </IonGrid>
            <ProfilePhoto key={"y"}/> 
            <IonRow className="bottomHalf">
              <div className="roundedBottomHalf">
                <IonGrid className="bottomGrid">
                  <IonRow>
                    <IonGrid className="profileInformation">
                      <IonRow className="pictureAndSettings">
                        <motion.img
                          whileTap={{rotateZ: 360, scale: 1.2}}
                          className="settings"
                          src={cog}
                          onClick={settingOnCLick}
                        />
                      </IonRow>
                      <IonRow>
                        <p className="name">{data.currentUser.uniqueUsername}</p>
                      </IonRow>
                      <IonRow>
                        <IonGrid>
                          <IonRow>
                            <IonCol className="challenges">
                              <p className="completedChallenges">{finishedChallenges}</p>
                              <p className="completedChallenges">Challenges</p>
                            </IonCol>
                            <IonCol className="streaks">
                              <p className="currentStreak">0</p>
                              <p className="currentStreak">Streak</p>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonRow>
                      <IonRow>
                        <ProfileInformation data={data}/>
                      </IonRow>

                      <IonRow>
                        <div className="line" />
                      </IonRow>
                    </IonGrid>
                  </IonRow>
                  {/* <IonRow className="achievements">
                    <p>achievements</p>
                    <div className="line" />
                    </IonRow> */}
                  <IonRow className="friends">
                    <div className="friendsContainer" >
                      <div className="friendsHeader">
                        <div></div>

                        <div>
                          <h1 className="friendsText">Vrienden</h1>
                        </div>

                        <div>
                          <IonItemOption mode={"md"} className="ion__friendItem" routerLink={"friends"}>
                            <motion.div
                              className="friend__button__container"
                              whileTap={{scale: 1.2}}
                            >
                              <IonIcon className="goToFriends" icon={addCircleOutline} size="large"></IonIcon>
                            </motion.div>                                                            
                          </IonItemOption>
                        </div>
                      </div>
                      <Friendlist/>       
                    </div>
                  </IonRow>
                  <div className="line" />
                  <div>
                    <h1 className="Achievement__Text">Achievements</h1>
                  </div>
                  <h1 style={{textAlign: "center"}}>👷🚧</h1>
                  <h2 style={{textAlign: "center"}}>Hier wordt nog druk aan gewerkt!</h2>
                </IonGrid>
              </div>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Profile;
