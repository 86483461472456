import { IonContent, IonPage } from "@ionic/react";
import LoginWidget from "../components/molecules/LoginWidget/LoginWidget";
import LoginHeader from "../components/molecules/LoginHeader/LoginHeader";
import { NavbarContext } from "../routes";
import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useHistory } from "react-router";

const LoginPage = () => {
  const {setShow} = React.useContext(NavbarContext);
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    setShow(false);
  }, []);

  useEffect(() => {
    if(auth.isAuthenticated){
      history.push({
        pathname: "/home",
      });
    }
  });

  return (
    <IonPage>
      <IonContent className="LoginPage">
        <LoginHeader />
        <LoginWidget />
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
