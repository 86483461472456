/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import {IonPage, IonInput, IonBackButton, IonContent} from "@ionic/react";
import { Camera, CameraResultType } from "@capacitor/camera";
import { useState, useEffect } from "react";
import "./photo.scss";
import Send from "../../../assets/send.svg";
import axios from "axios";
import {useMutation} from "urql";
import {createRegistration} from "../../../Queries/RegistrationQuery";
import ValidatieCard from "../../organisms/validatieCard";
import { useHistory } from "react-router-dom";
import Compressor from "compressorjs";

const photo = ({ChallengeId}) => {
  const history = useHistory();
  const [image, setImage] = useState("");
  const [imageURL, setImageURL] = useState(null);
  const [text, setText] = useState("");
  const [result, setregistration] = useMutation(createRegistration);
  const [animate, setAnimate] = useState(false);

  const variants = {
    send: {x: 100, scale: 0.9, transition: {ease: "easeOut", delay: 0.1}},
    none: {}
  };

  const backLocation = {
    pathname: "/challenge/" + ChallengeId
  };

  /**
   * Takes the picture and compresses it
   */
  const takePicture = async () => {
    const cameraResult = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl
    }).catch(() => {
      history.push(backLocation); 
      history.go();
      });
      let compressed = await compressPicture(dataURLtoFile(cameraResult.dataUrl));
      setImage(compressed);
    let reader = new FileReader();
    reader.readAsDataURL(compressed);
    reader.addEventListener("load", function () {
      setImageURL(reader.result);
    });
  };

  /**
   * Converts Image to from dataURL to File
   * @param {string} dataurl 
   * @param {string} filename 
   * @returns 
   */
  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1);
      n -= 1; 
    }
    return new File([u8arr], filename, { type: mime });
  };

  useEffect(() => {
    takePicture();
  }, []);

  /**
   * Creates a registration for the taken picture
   * @param {JSON} pos 
   */
  const createRegistrations = async (pos) => {
    await setregistration({value: text, challengeId: ChallengeId, x: pos.coords.longitude, y: pos.coords.latitude}).then(
      (result) => {
        let {data, fetching, error} = result;
        if(error) return <p>Oh no... {error.message}</p>;
        uploadPicture(data.createRegistration.uploadUrl);
      } 
    );
  };

  /**
   * Sends the registration
   */
  const sendRegistration = () => {
    navigator.geolocation.getCurrentPosition(createRegistrations); 
  };

  /**
   * Compresses the size of the taken picture
   * @param {dataURL} data 
   * @returns The compressed picture
   */
  const compressPicture = (data) => {
    return new Promise((resolve, reject) => {
      new Compressor(data, {
        quality: 0.6,
        convertSize: 50,
        convertTypes: ["image/png"],
        success(result){
          resolve(result);
        },
        error(err){
          reject(err);
        }
      });
    });
  };

  const handleKeyPress = (event) => {
    if(event.key == "Enter"){
      sendRegistration();
    }
  };
  
  /**
   * Uploads the picture
   * @param {string} route 
   */
  const uploadPicture = async (route) => {
    try {
      await axios.put(route, image);
      history.push(backLocation); 
      history.go();
          }
      catch(e){
        console.log(e);
      }      
    };

  return (
    <IonPage >
      <IonContent>
        <div className="photoPage">
        <IonBackButton className="backButton" defaultHref={"/challenge/" + ChallengeId}  />
        <div>
            <ValidatieCard
              correct={false}
              value={text}
              imageUrl={imageURL}
              selected={true}
              rotation={0}
              registrationId={0}
              onMatch={()=> {}}
              index={0}
              key={0}
            ></ValidatieCard>
        </div>
        <div className="description">
          <IonInput type="text" maxLength={60} value={text} enterkeyhint="send"
            placeholder={"Wat staat er op deze foto?"} onIonChange={e => setText(e.detail.value)} onKeyPress={handleKeyPress}>
          </IonInput>
        </div>
      </div>
      </IonContent>
    </IonPage>
  );
};

export default photo;
