/* eslint-disable no-unused-vars */
import { IonPage, IonBackButton, IonSegment, IonSegmentButton, IonLabel } from "@ionic/react";
import {useState} from "react";
import {NavbarContext} from "../../../routes";
import React, {useEffect} from "react";
import "./friendsInfo.scss";
import FriendInfolist from "../../organisms/FriendInfoList";

const FriendsInfo = () => {
  const {setShow} = React.useContext(NavbarContext);

  useEffect(() => {
    setShow(false);
  }, []);

  return (
    <IonPage className="friendsPage">
      <div className="header">
        <IonBackButton className="backButton" defaultHref="/profile" />
        <div className="headerText">
          <h2>Vrienden info</h2>
        </div>
        <FriendInfolist/>
      </div>

      <h1 style={{textAlign: "center"}}>👷🚧</h1>
      <h2 style={{textAlign: "center"}}>Hier wordt nog druk aan gewerkt en volgen er achievements!</h2>
    </IonPage>
  );
};

export default FriendsInfo;
