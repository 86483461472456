import logo from "../../../images/logo.svg";

function LoginHeader() {
  return (
    <div className="LoginHeader">
      <img className="LoginHeader__Image" src={logo} />
    </div>
  );
}

export default LoginHeader;
