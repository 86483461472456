import { IonSpinner } from "@ionic/react";
import { useEffect } from "react";
import "./spinner.scss";

const Spinner = ({onFinished}) => {

  useEffect(()=> {
    setTimeout(() => {
      if(typeof onFinished !== "undefined"){
        onFinished();
      } 
    }, 550);
    
  }, [onFinished]);

  return(
    <div className="spinner">
      <div className="Spinner__container">
        <IonSpinner className="loading__spinner" name="crescent" />
      </div>
      
    </div>
  );
};

export default Spinner;
