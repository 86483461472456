import React from "react";
import { setupIonicReact} from "@ionic/react";
import Layout from "./components/templates/Layout";


/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import "./style/style.scss";

/* Theme variables */
import "./theme/variables.css";

import { useAuth } from "react-oidc-context";
import { createClient, Provider } from "urql";
import { Routes } from "./routes";



setupIonicReact();

function App() {
  const auth = useAuth();

  function getToken() {
    if(auth.isAuthenticated) return auth.user.access_token;
    return "";
  }


  function getApiUrl(){
    return window.config.API_URL;
    /*const apiUrls = {
      "webapp-acc.behive.nl": "https://api-acc.behive.nl",
      "webapp.behive.nl": "https://api.behive.nl"
    };

    return apiUrls[window.location.hostname] ?? "https://api-test.behive.nl";*/
  }


  const client = createClient({
    url: `${getApiUrl()}/graphql`,
    fetchOptions: () => {
      const token = getToken();
      return {
        headers: { authorization: token ? `Bearer ${token}` : "" },
      };
    },
  });

  return (
    <Provider value={client}>
      <Routes>
        <Layout></Layout> 
      </Routes>
    </Provider>
  );
}

export default App;
