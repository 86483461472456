import { gql } from "urql";

export const validationQueue = gql`
    query {
        validationQueue {
            id
            value
            imageUrl
        }
    }
`;

export const validationMutation = gql`
    mutation($id: Int!, $value: Validations!) {
	validation(input: {
		registrationId: $id,
		value: $value
	})
}
`;
