import {gql} from "urql";

export const createRegistration = gql`
    mutation($value: String!, $challengeId: Int!, $x: Float!, $y: Float!) {
      createRegistration(input: {
          value: $value,
          challengeId: $challengeId,
          location: {
              x: $x,
              y: $y
          }
          typeId: 3
      }) {
          id
          value
          uploadUrl
      }
}`;

export const createQuestionRegistration = gql`
    mutation($value: String!, $challengeId: Int!, $questionId: Int!) {
      createQuestionRegistration(input: {
				challengeId: $challengeId
        questionId: $questionId
        value: $value
        typeId: 1
    }) {
        value
        xp
        correctAnswers
    }
}`;

export const getRegistrations = gql`
  query($id: String!) {
    challenge(id: $id){
      id
		  registrations {
        id
        imageUrl
        location {
          x,
          y
        }
      }
    }
  }`;
